import { apiInstance } from '@/api-requests/api-instance';
import postMessage from '@/utils/post-message';
import { AxiosError } from 'axios';

export const configureApiInterceptors = (_accessToken: string | null) => {
  apiInstance.interceptors.request.use((config) => {
    if (!_accessToken) {
      throw new Error('No access token exists');
    }

    config.headers.Authorization = `Bearer ${_accessToken}`;
    return config;
  });

  apiInstance.interceptors.response.use(null, async (error: AxiosError) => {
    if (error.config?.url?.includes('auth/token/refresh')) {
      // setIsTokenExpired(true);
      // Ensure that a failed request for a refresh token hits the catch block and emits an postMessage error
      return Promise.reject(error);
    }

    if (error.response?.status === 401 && error.config) {
      // TODO - refresh the token and try again

      window.parent.postMessage('invalidToken', '*'); // for backwards compatibility
      postMessage('invalidToken');
      // datadogRum.addError(new Error('refreshToken failed'));
      return Promise.reject(error);
    }

    return Promise.reject(error);
  });
};

export default configureApiInterceptors;
