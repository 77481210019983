// Saves access and refresh tokens to localstorage
export const saveStorageTokens = (
  accessToken: string | null,
  refreshToken: string | null,
) => {
  try {
    if (accessToken) {
      window.localStorage.setItem('access_token', accessToken);
    }

    if (refreshToken) {
      window.localStorage.setItem('refresh_token', refreshToken);
    }
  } catch {
    return;
    // do nothing
  }
};
