import { AppConfig } from './types';
import { defaultConfig } from './default';
import { inksoftConfig } from './inksoft';
import { OnboardingState } from '@/types/onboarding';
import { boostConfig } from './boost';
import { payrixConfig } from './payrix';
import { printavoConfig } from './printavo';

// TODO: use client IDs here instead of plain text similar to embedded-cbc config
// Fetch Custom CSS Configurations for clients
// see frontend-mono /apps/embedded-cbc/pages/_app.page.tsx#L97 for example of setting config properly
export const fetchConfig: Record<string, AppConfig> = {
  atelio: defaultConfig,
  boost: boostConfig,
  inksoft: inksoftConfig,
  payrix: payrixConfig,
  printavo: printavoConfig,
};

export const productDisplayName: Record<OnboardingState['flow_name'], string> =
  {
    'commercial-credit-card': 'Commercial Secured Card',
    kanmon: 'Lending',
  };
