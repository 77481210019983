export const postMessage = (
  event: string,
  payload: Record<string, unknown> = {},
) => {
  const message = {
    event,
    ...payload,
  };

  const stringifiedMessage = JSON.stringify(message);

  window.parent.postMessage(stringifiedMessage, '*');
  // @ts-ignore
  window.ReactNativeWebView?.postMessage(stringifiedMessage);
};

export default postMessage;
