'use client';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useAuthContext } from './auth';
import {
  GetOnboardingStateResponse,
  OnboardingState,
} from '@/types/onboarding';
import apiInstance from '@/api-requests/api-instance';
import { useQuery } from '@tanstack/react-query';
import { datadogRum } from '@datadog/browser-rum';
import { ToastContainer, toast } from 'react-toastify';
import { useRouter } from 'next/navigation';

interface Props {
  children: ReactNode;
  initialState?: Partial<OnboardingState>;
}

export interface Address {
  city: string;
  country: string;
  state: string;
  street: string;
  street2?: string;
  zip_code: string;
}

interface IOnboardingStore<T extends OnboardingState> {
  applicationId?: string | null;
  onboardingState?: T;
  setOnboardingState: Dispatch<SetStateAction<T | undefined>>;
  isLoading: boolean;
  navigateToPath: (path?: string) => void;
}

const OnboardingContext = createContext<IOnboardingStore<any>>({
  isLoading: true,
  navigateToPath: () => {},
  setOnboardingState: () => {},
});

export const useOnboardingContext = <T extends OnboardingState>() =>
  useContext<IOnboardingStore<T>>(OnboardingContext);

export const OnboardingProvider = ({ children, initialState }: Props) => {
  const { accessToken } = useAuthContext();
  const { push } = useRouter();

  const [onboardingState, setOnboardingState] = useState<
    Partial<OnboardingState> | undefined
  >(initialState);

  const { isLoading, data, error } = useQuery({
    enabled: !!accessToken,
    queryFn: async () => {
      const { data } =
        await apiInstance.get<GetOnboardingStateResponse<OnboardingState>>(
          '/v0/onboarding/',
        );
      return data;
    },
    queryKey: ['onboardingData'],
  });

  useEffect(() => {
    if (data?.state) {
      setOnboardingState(data.state);

      datadogRum.setUser({
        business_id: data.state.business_id,
        client_id: data.state.client_id,
      });
    }
  }, [data]);

  useEffect(() => {
    if (error?.message) toast.error(error.message);
  }, [error]);

  const navigateToPath = useCallback(
    (path?: string) => {
      if (path && data) {
        push(`/${data.state.flow_name}/${data.id}/${path}`);
      }
    },
    [data, push],
  );

  return (
    <OnboardingContext.Provider
      value={{
        applicationId: data?.id,
        isLoading,
        navigateToPath,
        onboardingState,
        setOnboardingState,
      }}
    >
      {children}
      <ToastContainer
        autoClose={3000}
        hideProgressBar
        position="bottom-left"
        theme="colored"
      />
    </OnboardingContext.Provider>
  );
};
