import { AppConfig } from './types';

// Printavo
export const printavoConfig: AppConfig = {
  theme: {
    'action-button-disabled-color': '#ACACAC',
    'action-button-hover-color': '#DCECD7',
    'action-button-text-color': '#000000',
    'action-color': '#DCECD7', // maps to old interaction color
    'primary-brand-color': '#51A63B', // maps to old action color
  },
};
