import { AppConfig } from './types';

// Inksoft
export const inksoftConfig: AppConfig = {
  theme: {
    'action-button-disabled-color': '#ACACAC',
    'action-button-hover-color': '#DAF3E6',
    'action-button-text-color': '#000000',
    'action-color': '#DAF3E6', // maps to old interaction color
    'primary-brand-color': '#48C585', // maps to old action color
  },
};
