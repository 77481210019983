import '@/styles/globals.css';
import type { AppProps } from 'next/app';
import localFont from 'next/font/local';
import { OnboardingProvider } from '@/contexts/onboarding';
import { QueryProvider } from '@/contexts/query-client';
import { AuthProvider } from '@/contexts/auth';
import { datadogRum } from '@datadog/browser-rum';
import { cn } from '@/utils/tailwind';
import './global.css';
import 'react-toastify/dist/ReactToastify.css';

if (process.env.NEXT_PUBLIC_APP_ENV === 'prod') {
  datadogRum.init({
    allowedTracingUrls: ['https://api-sandbox.lending.embedded.atelio.com'],
    applicationId: String(process.env.NEXT_PUBLIC_DD_APPLICATION_ID),
    beforeSend: (event) => {
      // Strip view
      event.view.url = event.view.url.replace(
        /access_token=[^&]*/,
        'access_token=REDACTED',
      );
      event.view.url = event.view.url.replace(
        /refresh_token=[^&]*/,
        'refresh_token=REDACTED',
      );
      // Strip referrer
      event.view.referrer = event.view.referrer?.replace(
        /access_token=[^&]*/,
        'access_token=REDACTED',
      );
      event.view.referrer = event.view.referrer?.replace(
        /refresh_token=[^&]*/,
        'refresh_token=REDACTED',
      );
      return true;
    },
    clientToken: String(process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN),
    defaultPrivacyLevel: 'mask-user-input',
    env: process.env.NEXT_PUBLIC_APP_ENV,
    service: process.env.NEXT_PUBLIC_DD_SERVICE_NAME,
    sessionReplaySampleRate: 100,
    sessionSampleRate: 100,
    site: 'datadoghq.com',
    trackLongTasks: true,
    trackResources: true,
    trackUserInteractions: true,
    useCrossSiteSessionCookie: true,
    version: process.env.NEXT_PUBLIC_RELEASE_VERSION,
  });
  datadogRum.startSessionReplayRecording();
}

const matterFont = localFont({
  src: [
    {
      path: '../public/fonts/Matter-Regular.woff2',
      style: 'normal',
      weight: '400',
    },
    {
      path: '../public/fonts/Matter-SemiBold.woff2',
      style: 'normal',
      weight: '500',
    },
    {
      path: '../public/fonts/Matter-Bold.woff2',
      style: 'normal',
      weight: '700',
    },
  ],
  variable: '--font-matter',
});

export default function App({ Component, pageProps }: AppProps) {
  return (
    <div className={cn('antialiased font-matter', matterFont.className)}>
      <AuthProvider>
        <QueryProvider>
          <OnboardingProvider>
            <main className="bg-bg-primary m-auto max-w-xl rounded-xl h-dvh">
              <Component {...pageProps} />
            </main>
          </OnboardingProvider>
        </QueryProvider>
      </AuthProvider>
    </div>
  );
}
